import React from 'react';

function Tile({ imageSrc, link, text, size = '100px', borderRadius = '10px' }) {
  const tileStyle = {
    width: size,
    height: size,
    borderRadius: borderRadius,
    overflow: 'hidden',
    position: 'relative',
    margin: '10px',
    textDecoration: 'none', // Ensure the link doesn't have the default underline
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Center image
  };

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',  // Ensures image fills the square without distortion
  };

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" style={tileStyle}>
      <div style={{ position: 'relative' }}>
        <img src={imageSrc} alt={text} style={imgStyle} />
        <div className="overlay"></div>
        <div className="text-overlay">{text}</div>
      </div>
    </a>
  );
}

export default Tile;
