import React from 'react';

function Writing() {
  return (
    <section id="writing" className="container my-5">
      <h2>Writing</h2>
      <p>Coming soon</p>
    </section>
  );
}

export default Writing;
