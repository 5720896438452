import React from 'react';
import image1 from '../assets/FreshmanYrHeadshot.jpeg';
import image2 from '../assets/TreeHacksDub.jpeg';
import image3 from '../assets/FriendsInConcurrency.JPG';
import Logo from '../components/CircularLogo';  // Import the Logo component

function About() {
  const images = [
    { src: image1, caption: 'Super old "headshot" my friends took of me freshman year that I will reuse till I drop dead' },
    { src: image2, caption: 'My best friends and I off 40 hours no sleep right before we won a huge prize at TreeHacks' },
    { src: image3, caption: 'My friends and I in Concurrency after getting destroyed on the final (you gotta smile through the pain)' }
  ];

  // Logos for languages and frameworks (example logos, replace with actual URLs)
  const logos = [
    { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1200px-ISO_C%2B%2B_Logo.svg.png', alt: 'C++' },
    { src: 'https://cdn-icons-png.flaticon.com/512/226/226777.png', alt: 'Java' },
    { src: 'https://cdn.iconscout.com/icon/free/png-256/free-python-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-vol-5-pack-logos-icons-3030224.png?f=webp', alt: 'Python' },
    { src: 'https://static.vecteezy.com/system/resources/previews/027/127/463/non_2x/javascript-logo-javascript-icon-transparent-free-png.png', alt: 'JavaScript' },
    { src: 'https://go.dev/blog/go-brand/Go-Logo/PNG/Go-Logo_Blue.png', alt: 'Go' },
    { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwuqWn7rCVhqZ_pSlxwVUzlZtFWaOMdbm28A&s', alt: 'SQL' }
  ];

  // Fixed height for carousel items
  const carouselItemStyle = { height: '600px' };
  // Ensure the image fills the container and is cropped appropriately
  const imageStyle = { height: '100%', width: '100%', objectFit: 'cover' };

  return (
    <section id="about" className="container my-5">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div id="aboutCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {images.map((img, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} style={carouselItemStyle}>
                  <img src={img.src} className="d-block w-100" alt={`Slide ${index + 1}`} style={imageStyle} />
                  <div className="carousel-caption d-block text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <strong>{img.caption}</strong>
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#aboutCarousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#aboutCarousel" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="col-md-6">
          {/* Row of logos above the text */}
          <div className="row justify-content-center mb-4">
            {logos.slice(0, 3).map((logo, index) => (
              <div key={index} className="col-4">
                <Logo imageSrc={logo.src} altText={logo.alt} />
              </div>
            ))}
          </div>

          <h2>Hey! I'm Sarvesh.</h2>
          <p>
            <br />
            I'm currently a software engineer at Meta (formerly Facebook (formerly TheFaceBook)). I went to UT Austin and was part of the 
            CSB program, which is a double major in Computer Science Honors and Business Honors. Formally, I enjoy problems in lower-level systems 
            engineering, distributed computing, and machine learning. Informally, I like building cool stuff and that's probably more important.
            <br />
            <br />
            Other than coding, I'm an amateur at a whole slew of things, but I really do love the process of getting better. 
            I love chess, playing guitar, table tennis, skateboarding, and 
            watching movies. There's a bunch of other things too, but I'm either too trash at it or 
            it's too lame to list. I'll give you just one: in 10th grade I got super into "pen spinning". I spent $50 on special "pens" for this and
            would post videos of me doing tricks to Snapchat. That was a hard year for me, reputation-wise.
          </p>

          {/* Row of logos below the text */}
          <div className="row justify-content-center mt-4">
            {logos.slice(3).map((logo, index) => (
              <div key={index} className="col-4">
                <Logo imageSrc={logo.src} altText={logo.alt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
