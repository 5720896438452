import React from 'react';
import Card from '../components/Card';

function WorkExperience() {
  const experiences = [
    {
        title: 'Meta',
        image: 'https://www.victoryxr.com/wp-content/uploads/2023/04/Meta-Logo-1024x576.png.webp',
        date: 'Aug 2025 - Curr',
        description: 'Software engineer. Team still TBD. Hopefully working on something cool like AR, LLMs, comms, or compute optimization stuff.',
        link: 'https://about.meta.com/',
        height: '150px'
    },
    {
        title: 'IMC Trading',
        image: 'https://mma.prnewswire.com/media/2017000/IMC_Blue_Logo.jpg?p=facebook',
        date: 'Jun 2024 - August 2024',
        description: 'Intern on the VMM Strategy desk. Used Java and Python to implement custom TCP wrapper for mass message transfer.',
        link: 'https://www.imc.com/us',
        height: '150px'
    },
    {
        title: 'MathWorks',
        image: 'https://seekvectorlogo.net/wp-content/uploads/2020/03/mathworks-vector-logo.png',
        date: 'May 2023 - July 2023',
        description: 'Intern on Core UI Graphics team. Used C++ and JavaScript to fix major backend and frontend issues for Matlab Desktop.',
        link: 'https://www.mathworks.com/',
        height: '150px'
    },
    {
        title: 'Hewlett-Packard Enterprise',
        image: 'https://assets.weforum.org/organization/image/QW2cYheWVTPiii5t9DbOdLHNxmGYYhnFtCHyzQwhas8.jpeg',
        date: 'May 2022 - August 2022',
        description: 'Intern on Tiger Sales team. Used JavaScript, Python, SQL, and some custom Linux tooling to automate equipment management.',
        link: 'https://www.hpe.com/us/en/home.html',
        height: '150px'
    }
  ];

  return (
    <section id="work" className="container my-5">
      <h2 className="mb-4">Work Experience</h2>
      <div className="d-flex flex-wrap justify-content-center">
        {experiences.map((exp, index) => (
          <Card key={index} {...exp} />
        ))}
      </div>
    </section>
  );
}

export default WorkExperience;
