import React, { useState, useEffect } from 'react';
import NavigationBar from './components/NavigationBar';
import About from './sections/About';
import WorkExperience from './sections/Work';
import Projects from './sections/Projects';
import Writing from './sections/Writing';
import Contact from './sections/Contact';
import ThemeToggle from './components/ThemeToggle';

function App() {
  const [theme, setTheme] = useState('light');
  
  const toggleTheme = () => {
    setTheme(prev => (prev === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    // Update the document body class based on theme
    document.body.className = theme + '-theme';
  }, [theme]);

  return (
    <div>
      <NavigationBar />
      <div className="content-wrapper">
        <About />
        <WorkExperience />
        <Projects />
        <Writing />
        <Contact />
      </div>
      <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
    </div>
  );
}

export default App;
