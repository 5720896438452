import React from 'react';
import Card from '../components/Card';

function Projects() {
  const projects = [
    {
        title: 'Ranger',
        image: 'https://d112y698adiu2z.cloudfront.net/photos/production/challenge_photos/000/929/496/datas/original.png',
        date: '2025',
        description: 'Stanford hackathon project, visualizing sound for the deaf using AR and edge computing. Won "Best Use of Accelerated Compute" prize from Nvidia.',
        link: 'https://devpost.com/software/ranger-6jkv5s',
        height: '125px'
    },
    {
        title: 'PicTag',
        image: 'https://cdn.prod.website-files.com/6402cb0cae68453e8a6bb179/640ba224ba6752e7d5e2d006_pictag%20w%20logo%20transparent.png',
        date: '2022-2025',
        description: 'Startup my friends and I built providing AI image search at enterprise scale. Worked with multinational Brazilian energy company and NASA.',
        link: 'https://www.pictag.ai/',
        height: '125px'
    },
    {
        title: 'Deep Proteins',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUtY_sYXLLFEmLMNhFuJzOLvNt3Bp6AGf9sw&s',
        date: '2023-2024',
        description: 'Worked with Institute for Foundations of Machine Learning lab to build massive protein language models and aid in various biocomputation tasks.',
        link: 'https://ml.utexas.edu/deep-proteins',
        height: '125px'
    }
  ];

  return (
    <section id="projects" className="container my-5">
      <h2 className="mb-4">Projects</h2>
      <div className="d-flex flex-wrap justify-content-center">
        {projects.map((project, index) => (
          <Card key={index} {...project} />
        ))}
      </div>
    </section>
  );
}

export default Projects;
