import React from 'react';

function Card({ image, date, description, title, link, height = '200px' }) {
  // Fixed height for image, customizable via 'height' prop
  const imageStyle = { 
    height: height,  // Use the height passed via props, default to '200px'
    width: '100%',
    objectFit: 'fill' 
  };

  return (
    <div className="card m-3" style={{ width: '18rem' }}>
      <a href={link} target="_blank" rel="noopener noreferrer" className="card-link-wrapper">
        <div className="card-img-container" style={{ position: 'relative' }}>
          <img src={image} className="card-img-top" alt={title} style={imageStyle} />
          <div className="overlay">
          </div>
        </div>
      </a>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{date}</h6>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
}

export default Card;
