import React from 'react';

function Logo({ imageSrc, altText, size = '80px' }) {
  const logoStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    objectFit: 'cover',
    margin: '10px',
  };

  return <img src={imageSrc} alt={altText} style={logoStyle} />;
}

export default Logo;
