import React from 'react';

function ThemeToggle({ theme, toggleTheme }) {
  return (
    <button 
      onClick={toggleTheme}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '75px',
        height: '75px',
        borderRadius: '50%',
        border: 'none',
        backgroundColor: theme === 'light' ? '#333' : '#ddd',
        color: theme === 'light' ? '#fff' : '#333',
        boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
        cursor: 'pointer',
        fontSize: '30px'
      }}
      aria-label="Toggle Theme"
    >
      {theme === 'light' ? '🌙' : '☀️'}
    </button>
  );
}

export default ThemeToggle;
