import React from 'react';
import Tile from '../components/Tile'; // Import Tile component

function Contact() {
  return (
    <section id="contact" className="container my-5">
      <h2>Where to Find Me</h2>
      <div className="d-flex justify-content-center flex-wrap">
        <Tile
          imageSrc="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/2048px-Octicons-mark-github.svg.png" // GitHub logo
          link="https://github.com/SarveshSub/"
          text=""
          size="100px" // Ensure same size for all icons
        />
        <Tile
          imageSrc="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" // LinkedIn logo
          link="https://www.linkedin.com/in/sarveshpsubramanian/"
          text=""
          size="100px" // Ensure same size for all icons
        />
        <Tile
          imageSrc="https://cdn0.iconfinder.com/data/icons/material-circle-apps/512/icon-email-material-design-512.png"  // Mail logo
          link="mailto:sarvesh.p.subramanian@gmail.com"
          text=""
          size="100px" // Ensure same size for all icons
        />
        <Tile
          imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWJMo-zI-tOARZae9clUFd05oWEvKs52QbaA&s"  // Chess logo
          link="https://www.chess.com/member/phoenixphighter"
          text=""
          size="100px" // Ensure same size for all icons
        />
        <Tile
          imageSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH45TgrphrMnMTlMx9wpG_Jj7JoBzrI9zAfg&s"  // Letterboxd logo
          link="https://letterboxd.com/PhoenixReviews/"
          text=""
          size="100px" // Ensure same size for all icons
        />
      </div>
    </section>
  );
}

export default Contact;
